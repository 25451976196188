<template>
  <div class="case-detail-wrapper">
    <div class="content-wrapper">
      <div class="card-box">
        <h4 class="title border-bottom-1px">上报信息</h4>
        <div class="info">
          <h5>问题编号</h5>
          <p>{{detail.questionNum}}</p>
        </div>
        <div class="info">
          <h5>问题类型</h5>
          <p>{{detail.questionTypeName}}</p>
        </div>
        <div class="info">
          <h5>处理状态</h5>
          <p>{{detail.questionStatus | statusFilter}}</p>
        </div>
        <div class="info">
          <h5>问题描述</h5>
          <p>{{detail.questionDesc}}</p>
        </div>
        <div class="info">
          <h5>处理前</h5>
          <div class="img-list">
            <img @click="previewImg(detail.reportImgUrls, index)" v-for="(img, index) in detail.reportImgUrls" v-lazy="img" :key="index">
          </div>
        </div>
        <div class="info">
          <h5>现场地址</h5>
          <p>{{detail.questionAddr}}</p>
        </div>
        <div class="info">
          <h5>上报时间</h5>
          <p>{{detail.createTime}}</p>
        </div>
      </div>
      <div class="card-box" v-if="detail.handleTime">
        <h4 class="title border-bottom-1px">处理信息</h4>
        <div class="info">
          <h5>处理后</h5>
          <div class="img-list">
            <img @click="previewImg(detail.handleImgUrls, index)" v-for="(img, index) in detail.handleImgUrls" v-lazy="img" :key="index">
          </div>
        </div>
        <div class="info">
          <h5>处理描述</h5>
          <p>{{detail.ccHandelDesc}}</p>
        </div>
        <div class="info">
          <h5>处理时间</h5>
          <p>{{detail.handleTime}}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import APIS from '@/api/index'
const { getDiscloseDetail } = APIS
export default {
  name: 'caseDetail',
  data () {
    return {
      detail: {}
    }
  },
  filters: {
    statusFilter (val) {
      switch (val) {
        case 1: return '待审核'
        case 2: return '审核通过'
        case 3: return '审核不通过'
        case 4: return '不予受理'
        case 5: return '结案'
        case 6: return '其他'
        case 7: return '重复案件'
      }
    }
  },
  activated () {
    this.getDiscloseDetail()
  },
  methods: {
    previewImg (images, startPosition) {
      this.$imagePreview({
        images,
        startPosition
      })
    },
    async getDiscloseDetail () {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true
      })

      let discloseId = this.$route.params.id
      let res = await getDiscloseDetail({ discloseId })

      loading.clear()

      if (res.success) {
        this.detail = res.data
      } else {
        this.$toast(res.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .case-detail-wrapper{
    .card-box{
      margin-bottom: 20px;
      .title{
        font-size: 32px;
        color: #333;
        padding-bottom: 24px;
        font-weight: bold;
        margin-bottom: 24px;
      }
      .info{
        display: flex;
        margin-top: 10px;
        font-size: 28px;
        >h5{
          width: 120px;
          line-height: 1.5;
          color: #666;
          font-weight: 500;
        }
        >p{
          margin-left: 25px;
          line-height: 1.5;
          flex: 1;
          color: #677881;
        }
        .img-list{
          margin-left: 25px;
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          img{
            width: 164px;
            height: 164px;
            margin-bottom: 12px;
            &:not(:nth-child(3n)){
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
</style>
